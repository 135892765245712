var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.label}},[_c('flat-pickr',{class:[_vm.classe, _vm.isError && 'form-error'],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"config":_vm.type == 'data'
          ? {
              altInput: true,
              altFormat: _vm.formatAltData('date'),
              dateFormat: 'Y-m-d',
              mode: _vm.mode,
              position: _vm.position,
              minDate: _vm.minDate,
              maxDate: _vm.maxDate,
              defaultDate: _vm.defaultDate,
              disableMobile: true,
              onOpen: function (selectedDates, dateStr, instance) {
                instance.setDate(_vm.defaultDate);
              }
            }
          : _vm.type == 'datahora'
          ? {
              altInput: true,
              altFormat: _vm.formatAltData('dateHour'),
              time_24hr: true,
              enableTime: true,
              dateFormat: 'Y-m-d H:i:S',
              enableSeconds: true,
              minuteIncrement: 1,
              mode: _vm.mode,
              position: _vm.position,
              minDate: _vm.minDate,
              maxDate: _vm.maxDate,
              defaultDate: _vm.defaultDate,
              disableMobile: true,
              onOpen: function (selectedDates, dateStr, instance) {
                instance.setDate(_vm.defaultDate);
              }
            }
          : {
              altInput: true,
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
              time_24hr: true,
              altFormat: 'H:i',
              disableMobile: true,
              minuteIncrement: 1,
              static: true
            }},on:{"on-change":function($event){_vm.type != 'hora' ? _vm.updateSelectValue(_vm.valueLocal) : ''},"input":function($event){_vm.type == 'hora' || _vm.type == 'datahora' ? _vm.updateSelectValue(_vm.valueLocal) : ''}},model:{value:(_vm.valueLocal),callback:function ($$v) {_vm.valueLocal=$$v},expression:"valueLocal"}}),(_vm.type == 'data' || _vm.type == 'datahora')?_c('calendarIcon',{staticClass:"calendar-icon-datepk"}):_c('timeIcon')],1),(_vm.isError)?_c('p',{staticClass:"msgError"},[_vm._v(" "+_vm._s(_vm.msgError)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }