var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar"},[_c('div',{staticClass:"grid calendar-week-days"},_vm._l((_vm.weekDays),function(item){return _c('span',{key:item.short},[_vm._v(_vm._s(item.short))])}),0),_c('div',{ref:"calendar",staticClass:"grid calendar-days"},_vm._l((_vm.generateCalendar()),function(item){return _c('button',{key:((item.date.date()) + "-" + (item.date.month()) + "-" + (item.date.year())),staticClass:"calendar-day-button",class:{
        today: item.date.isSame(_vm.today, 'day'),
        highlighted:
          (!_vm.range &&
            _vm.selectedDay === item.date.date() &&
            _vm.selectedMonth === item.date.month() &&
            _vm.selectedYear === item.date.year()) ||
          (_vm.range && (item.date.isSame(_vm.firstDate, 'day') || item.date.isSame(_vm.lastDate, 'day'))),
        range: _vm.range && item.date > _vm.firstDate && item.date < _vm.lastDate,
        current: item.currentMonth,
        disabled: _vm.disabled || item.disabled,
        blocked: _vm.blockDates(item.date),
        'in-range': _vm.lastDate === null && _vm.isInRange(item.date),
        'out-range': _vm.lastDate === null && !_vm.isInRange(item.date)
      },attrs:{"disabled":_vm.disabledDates(item.date, item.disabled)},on:{"click":function($event){return _vm.changeDay(item)}}},[_vm._v(" "+_vm._s(item.date.date())+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }