<template>
  <div class="time-picker">
    <div class="time-picker-column">
      <div class="navigation">
        <button @click="panTop('hour')"><CaretUp /></button>
      </div>
      <div class="picker-main" ref="hourScroll">
        <ul ref="hourList">
          <li
            v-for="(value, index) in hours"
            :key="index"
            :class="{
              highlighted: index == selectedHour,
              disabled
            }"
            @click="changeHour(index)"
            :data-value="index"
          >
            {{ formattedDigit(value) }}
            <span v-show="format == 12">{{ amPm(index) }}</span>
          </li>
        </ul>
      </div>
      <div class="navigation">
        <button @click="panDown('hour')"><CaretDown /></button>
      </div>
    </div>
    <div class="time-picker-column">
      <div class="navigation">
        <button @click="panTop('minute')"><CaretUp /></button>
      </div>
      <div class="picker-main" ref="minuteScroll">
        <ul ref="minuteList">
          <li
            v-for="(_, index) in 60"
            :key="index"
            :class="{
              highlighted: index == selectedMinute,
              disabled
            }"
            @click="changeMinute(index)"
            :data-value="index"
          >
            {{ formattedDigit(index) }}
          </li>
        </ul>
      </div>
      <div class="navigation">
        <button @click="panDown('minute')"><CaretDown /></button>
      </div>
    </div>
    <div class="time-picker-column" v-show="showSeconds">
      <div class="navigation">
        <button @click="panTop('second')"><CaretUp /></button>
      </div>
      <div class="picker-main" ref="secondScroll">
        <ul ref="secondList">
          <li
            v-for="(_, index) in 60"
            :key="index"
            :class="{
              highlighted: index == selectedSeconds,
              disabled
            }"
            @click="changeSeconds(index)"
            :data-value="index"
          >
            {{ formattedDigit(index) }}
          </li>
        </ul>
      </div>
      <div class="navigation">
        <button @click="panDown('second')"><CaretDown /></button>
      </div>
    </div>
    <div class="time-picker-active" v-if="showSeconds" ref="activeContainer">
      <span>:</span>
      <span>:</span>
    </div>
    <div class="time-picker-active" v-else ref="activeContainer">
      <span>:</span>
    </div>
  </div>
</template>
<script>
  import CaretDown from '@/assets/images/icons/picker-caret-down.svg';
  import CaretUp from '@/assets/images/icons/picker-caret-up.svg';

  export default {
    props: ['date', 'showSeconds', 'format', 'disabled', 'showPicker'],
    components: { CaretDown, CaretUp },
    data() {
      return {
        selectedHour: this.date.getHours(),
        selectedMinute: this.date.getMinutes(),
        selectedSeconds: this.date.getSeconds(),
        hours: []
      };
    },
    methods: {
      initValues() {
        this.selectedHour = this.date.getHours();
        this.selectedMinute = this.date.getMinutes();
        this.selectedSeconds = this.date.getSeconds();
      },
      amPm(value) {
        return value >= 12 ? 'pm' : 'am';
      },
      changeTime() {
        this.$emit('changeTime', {
          hours: this.selectedHour,
          minutes: this.selectedMinute,
          seconds: this.selectedSeconds
        });
      },
      changeHour(hours) {
        this.selectedHour = hours;
      },
      changeMinute(minutes) {
        this.selectedMinute = minutes;
      },
      changeSeconds(seconds) {
        this.selectedSeconds = seconds;
      },
      panTop(section) {
        if (section == 'hour') {
          this.selectedHour = this.selectedHour - 1 == -1 ? 23 : this.selectedHour - 1;
        } else if (section == 'minute') {
          this.selectedMinute = this.selectedMinute - 1 == -1 ? 59 : this.selectedMinute - 1;
        } else {
          this.selectedSeconds = this.selectedSeconds - 1 == -1 ? 59 : this.selectedSeconds - 1;
        }
      },
      panDown(section) {
        if (section == 'hour') {
          this.selectedHour = this.selectedHour + 1 == 24 ? 0 : this.selectedHour + 1;
        } else if (section == 'minute') {
          this.selectedMinute = this.selectedMinute + 1 == 60 ? 0 : this.selectedMinute + 1;
        } else {
          this.selectedSeconds = this.selectedSeconds + 1 == 60 ? 0 : this.selectedSeconds + 1;
        }
      },
      formattedDigit(value) {
        return String(value).padStart(2, '0');
      },
      scrollActualHour() {
        this.$nextTick(() => {
          const hourScroll = this.$refs.hourScroll;
          if (hourScroll) {
            const selectedHour = hourScroll.querySelector('.highlighted');
            let margin =
              window.innerWidth <= 480
                ? (selectedHour.offsetHeight + 48) * this.selectedHour
                : window.innerWidth <= 1000
                ? (selectedHour.offsetHeight + 89) * this.selectedHour
                : (selectedHour.offsetHeight + 32) * this.selectedHour;
            if (selectedHour) {
              hourScroll.scrollTop = margin;
            }
          }
        });
      },
      scrollActualMinute() {
        this.$nextTick(() => {
          const minuteScroll = this.$refs.minuteScroll;
          if (minuteScroll) {
            const selectedMinute = minuteScroll.querySelector('.highlighted');
            let scroll =
              window.innerWidth <= 480
                ? (selectedMinute.offsetHeight + 48) * this.selectedMinute
                : window.innerWidth <= 1000
                ? (selectedMinute.offsetHeight + 89) * this.selectedMinute
                : (selectedMinute.offsetHeight + 32) * this.selectedMinute;

            if (selectedMinute) {
              minuteScroll.scrollTop = scroll;
            }
          }
        });
      },
      scrollActualSecond() {
        this.$nextTick(() => {
          const secondScroll = this.$refs.secondScroll;
          if (secondScroll) {
            const selectedSeconds = secondScroll.querySelector('.highlighted');
            let scroll =
              window.innerWidth <= 480
                ? (selectedSeconds.offsetHeight + 48) * this.selectedSeconds
                : window.innerWidth <= 1000
                ? (selectedSeconds.offsetHeight + 89) * this.selectedSeconds
                : (selectedSeconds.offsetHeight + 32) * this.selectedSeconds;

            if (selectedSeconds) {
              secondScroll.scrollTop = scroll;
            }
          }
        });
      },
      setDraggableEvents(list, vm) {
        const preventClick = (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        };

        let mouseDown = false;
        var isDragged = false;
        let startX;
        let scrollTop;

        const startDragging = function (e) {
          list.classList.add('dragging');
          mouseDown = true;
          startX = e.pageY - list.offsetTop;
          scrollTop = list.scrollTop;
        };

        const stopDragging = function (event) {
          list.classList.remove('dragging');
          mouseDown = false;
          setTimeout(() => {
            vm.scrollAllActual();
          }, 1500);
        };

        list.addEventListener('mouseup', (e) => {
          mouseDown = false;
          const elements = document.getElementsByClassName('book');
          if (isDragged) {
            for (let i = 0; i < elements.length; i++) {
              elements[i].addEventListener('click', preventClick);
            }
          } else {
            for (let i = 0; i < elements.length; i++) {
              elements[i].removeEventListener('click', preventClick);
            }
          }
          list.classList.remove('active');
          isDragged = false;
          setTimeout(() => {
            vm.scrollAllActual();
          }, 1500);
        });

        list.addEventListener('mousemove', (e) => {
          if (!mouseDown) return;
          isDragged = true;
          e.preventDefault();
          const x = e.pageY - list.offsetTop;
          const walk = (x - startX) * 2;
          list.scrollTop = scrollTop - walk;
        });

        list.addEventListener('touchend', () => {
          setTimeout(() => {
            vm.scrollAllActual();
          }, 500);
        });

        list.addEventListener('mousedown', startDragging, false);
        list.addEventListener('mouseleave', stopDragging, false);
      },
      hourFormat() {
        var hourList = [];
        for (let index = 0; index < this.format; index++) {
          hourList.push(index);
        }

        if (this.format == 12) {
          hourList.push(12);
          for (let index = 1; index < this.format; index++) {
            hourList.push(index);
          }
        }

        this.hours = hourList;
      },
      scrollAllActual() {
        this.$nextTick(() => {
          this.scrollActualHour();
          this.scrollActualMinute();
          this.scrollActualSecond();
        });
      },
      setIntersection(list, container, section) {
        this.$nextTick(() => {
          const options = {
            root: container,
            treshold: 0
          };

          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                if (section == 'hour') {
                  this.selectedHour = parseInt(entry.target.dataset.value);
                } else if (section == 'minute') {
                  this.selectedMinute = parseInt(entry.target.dataset.value);
                } else {
                  this.selectedSeconds = parseInt(entry.target.dataset.value);
                }
              }
            });
          }, options);

          list.children.forEach((item) => {
            observer.observe(item);
          });
        });
      }
    },
    mounted() {
      const vm = this;
      this.setDraggableEvents(this.$refs.hourScroll, vm);
      this.setDraggableEvents(this.$refs.minuteScroll, vm);
      this.setDraggableEvents(this.$refs.secondScroll, vm);

      this.setIntersection(this.$refs.hourList, this.$refs.hourScroll, 'hour');

      this.setIntersection(this.$refs.minuteList, this.$refs.minuteScroll, 'minute');

      this.setIntersection(this.$refs.secondList, this.$refs.secondScroll, 'second');

      this.hourFormat();

      this.scrollAllActual();
    },
    watch: {
      format() {
        this.hourFormat();
      },
      selectedHour() {
        this.$nextTick(() => {
          this.scrollActualHour();
          this.changeTime();
        });
      },
      selectedMinute() {
        this.$nextTick(() => {
          this.scrollActualMinute();
          this.changeTime();
        });
      },
      selectedSeconds() {
        this.$nextTick(() => {
          this.scrollActualSecond();
          this.changeTime();
        });
      },
      showPicker() {
        this.initValues();
        this.scrollAllActual();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .time-picker {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .time-picker-active {
      width: 398px;
      height: 42px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: #4c4541;
      font-weight: 600;
      font-size: 16px;
      border-block: 1px solid #974900;
      background: transparent;
      pointer-events: none;
      position: absolute;
    }
    .time-picker-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-block: 8px;
      gap: 8px;
      .navigation {
        padding-block: 8px;
        button {
          background-color: #fff;
          border: none;
          border-radius: 6px;
          outline: none;

          &:hover {
            background: #ffede2;
          }
        }
      }

      .picker-main {
        width: 100%;
        text-align: center;
        height: 10rem;
        overflow-y: scroll;
        padding-block: 57px;

        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: column;
          gap: 32px;
          li {
            cursor: pointer;
            color: #cfc4be;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8;
            line-height: 26px;
            font-size: 16px;
            user-select: none;
            &.highlighted {
              font-weight: 600;
              color: #4c4541;
            }

            &.disabled {
              color: #cfc4be;
              pointer-events: none;
            }

            span {
              font-size: 12px;
              line-height: 16px;
              margin-left: 8px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .time-picker {
      height: calc(100% - 164px);
      .time-picker-active {
        width: 100%;
        height: 90px;
        font-size: 60px;
      }
      .time-picker-column {
        padding-block: 16px;
        gap: 31px;

        .picker-main {
          height: 22rem;
          padding-block: 130px;
          ul {
            gap: 89px;
            li {
              font-size: 60px;
              line-height: 50px;
              span {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }
        .navigation button {
          svg {
            transform: scale(4);
          }
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 830px) {
    .time-picker {
      height: calc(100% - 164px);
      .time-picker-active {
        width: 100%;
        height: 90px;
        font-size: 60px;
      }
      .time-picker-column {
        padding-block: 0px;
        gap: 31px;

        .picker-main {
          height: 10rem;
          padding-block: 48px;
          ul {
            gap: 89px;
            li {
              font-size: 60px;
              line-height: 50px;
              span {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }
        .navigation button {
          svg {
            transform: scale(4);
          }
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 635px) {
    .time-picker {
      height: calc(100% - 100px);

      .time-picker-active {
        height: 65px;
        font-size: 25px;
      }
      .time-picker-column {
        gap: 15px;

        .picker-main {
          height: 6rem;
          padding-block: 18px;
          ul {
            li {
              font-size: 40px;
              line-height: 50px;
              span {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 405px) {
    .time-picker {
      height: calc(100% - 60px);

      .time-picker-active {
        height: 50px;
        font-size: 25px;
      }
      .time-picker-column {
        gap: 5px;

        .picker-main {
          height: 5rem;
          padding-block: 10px;
          ul {
            li {
              font-size: 28px;
              line-height: 50px;
              span {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }

        .navigation button {
          svg {
            transform: scale(2);
          }
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 330px) {
    .time-picker {
      height: calc(100% - 60px);

      .time-picker-active {
        height: 50px;
        font-size: 25px;
      }
      .time-picker-column {
        gap: 0px;

        .picker-main {
          height: 5rem;
          padding-block: 10px;
          ul {
            li {
              font-size: 28px;
              line-height: 50px;
              span {
                font-size: 22px;
                line-height: 30px;
              }
            }
          }
        }
        .navigation {
          padding-block: 2px;
        }
        .navigation button {
          svg {
            transform: scale(2);
          }
          overflow: hidden;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .time-picker {
      height: calc(100% - 103px);
      .time-picker-active {
        width: 100%;
        height: 42px;
        font-size: 16px;
      }
      .time-picker-column {
        width: 100%;
        padding-block: 8px;
        gap: 8px;

        .picker-main {
          padding-block: 140px;
          height: 22rem;
          ul {
            gap: 48px;
            li {
              font-size: 16px;
              line-height: 26px;
              span {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
        .navigation button {
          svg {
            transform: scale(1);
          }
        }
      }
    }
  }

  @media (max-width: 480px) and (max-height: 570px) {
    .time-picker {
      .time-picker-column {
        .picker-main {
          height: 16rem;
          padding-block: 100px;
        }
      }
    }
  }
</style>
