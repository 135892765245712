<template>
  <ul class="fld__dropdown-c">
    <li>
      <button
        class="fld__dropdown-button"
        @click="() => handleShowDropdown()"
      >
        <span class="fld__dropdown-text">
          {{ $t(selectedOption.text) }}
        </span>
        <span class="fld__dropdown-icon">
          <DropdownIcon />
        </span>
      </button>
      <ul
        v-show="showDropdownList"
        :class="[{ '--left': position === 'left', '--right': position === 'right' }]"
      >
        <li
          v-for="(option, index) in options"
          :key="`filter-option-${index}`"
          :class="[
            {
              '--drop-active': selectedOption.value === option.value,
              '--datepicker-item': option.text.includes('Custom')
            }
          ]"
          @click="selectOption(option.value)"
        >
          <div
            v-if="!option.text.includes('Custom')"
            class="fld__dropdown-option"
          >
            {{ $t(option.text) }}
          </div>
          <DateTimePicker
            v-if="option.text.includes('Custom')"
            v-model="datepicker"
            position="left"
            ref="dateTimePickerRef"
            :range="true"
            :weekDays="setWeekDays()"
            :month-names="setMonths()"
            :txt-cancel-button="$t('Datepicker.Cancel')"
            :txt-save-button="$t('Datepicker.Save')"
            :txt-time="$t('Datepicker.Time')"
            :txt-date="$t('Datepicker.Date')"
            :maxRangeDates="maxRangeDates"
            @input="handleDatepickerChange"
            @cancel="handleDatepickerClose('cancel')"
            @save="handleDatepickerClose('save')"
          >
            <template v-slot:input>
              <div class="fld__dropdown-option datepicker-option">
                {{ $t(option.text) }}
              </div>
            </template>
          </DateTimePicker>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
  import DropdownIcon from '@/@core/assets/icons/arrow-down-orange.svg';
  import DateTimePicker from '@core/components/date-time-picker/DatePickerFull.vue';

  export default {
    name: 'FilterDays',
    components: {
      DropdownIcon,
      DateTimePicker
    },
    props: {
      options: {
        type: Array,
        default: [],
        required: true
      },
      position: {
        type: String,
        default: 'right'
      },
      maxRangeDates: {
        type: Number,
        default: 7 //periodo de 7 dias
      }
    },
    data: () => ({
      datepicker: [],
      lang: localStorage?.getItem('language'),
      allowClose: false,
      selectedOption: {},
      showDropdownList: false
    }),
    computed: {
      defaultDate() {
        //Periodo de dias pré selecionados
        return [
          new Date(new Date().setDate(new Date().getDate() - (this.maxRangeDates - 1))),
          new Date()
        ];
      }
    },
    created() {
      this.datepicker = [...this.defaultDate];
      this.selectedOption = { ...this.options[0] };
    },
    methods: {
      handleShowDropdown(flag = null) {
        this.showDropdownList = flag === null ? !this.showDropdownList : flag;
      },
      handleDatepickerChange(value) {
        this.datepicker = value;
      },
      selectOption(value) {
        const selectedOption = this.options.find((option) => option.value === value);
        this.selectedOption = { ...selectedOption };
        const isCustomOption = selectedOption.text.includes('Custom');

        if (!isCustomOption) {
          this.handleShowDropdown(false);
          this.$emit('change', {
            option: value
          });
          this.resetDatepicker();
        }
      },
      handleDatepickerClose(action) {
        action === 'save' &&
          this.$emit('change', {
            option: null,
            datepicker: this.datepicker
          });

        this.handleShowDropdown(false);
      },
      setWeekDays() {
        const weekdays = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ];

        return weekdays.map((day) => ({
          name: this.$t(`Datepicker.Weekdays.Full.${day}`),
          short: this.$t(`Datepicker.Weekdays.Short.${day}`)
        }));
      },
      setMonths() {
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ];

        return months.map((month) => ({
          name: this.$t(`Datepicker.Months.Full.${month}`),
          short: this.$t(`Datepicker.Months.Short.${month}`)
        }));
      },
      resetDatepicker() {
        this.datepicker = [...this.defaultDate];
      }
    }
  };
</script>

<style lang="scss">
  .fld__dropdown-c {
    border: none !important;
    padding: 0;
    margin: 0;
    user-select: none;
    display: flex;

    li {
      list-style: none;
    }

    & > li {
      position: relative;

      .fld__dropdown-button {
        height: 28px;
        padding: 3px 9px;
        border-radius: 5px;
        background: #fff;
        border: 1px solid #974900;

        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        &:hover {
          background: #ffede2;
        }

        &:active {
          background: #ffdbc4;
        }

        .fld__dropdown-text {
          color: #974900 !important;
          font-size: 12px !important;
          font-weight: 600 !important;
          line-height: 20px !important;
          letter-spacing: 0.15px;
        }

        .fld__dropdown-icon {
          display: flex;

          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    & ul {
      width: 180px;
      max-width: 180px;
      padding: 8px 0 !important;
      margin: 0 !important;
      border-radius: 6px !important;
      background: #fff;
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);

      position: absolute;
      top: 32px;
      z-index: 3;

      display: flex;
      flex-direction: column;

      &.--right {
        left: 0;
      }

      &.--left {
        right: 0;
      }

      & li {
        .fld__dropdown-option {
          padding: 12px 21px;
          color: #998f8a;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 20px !important;
          color: #974900 !important;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            color: #974900;
            background: #ffede2 !important;
          }

          &:active {
            color: #974900;
            background: #ffdbc4 !important;
          }
        }

        &.--drop-active {
          .fld__dropdown-option {
            background: #974900 !important;
            color: #fff !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .fld__dropdown-c {
      & > li {
        width: 100%;

        .fld__dropdown-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
</style>
