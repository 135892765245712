<template>
  <div class="header-days">
    <div class="header-days-navigation">
      <button @click="panLeft" v-show="showPanLeft">
        <CaretLeft />
      </button>
    </div>
    <div class="header-days-main" ref="daysList">
      <button
        class="header-days-button"
        @click="getPrevMonth"
        :disabled="disabled ? true : blockDates(prevDate)"
        v-show="showPrevMonth"
      >
        <p>
          {{ monthNames[prevMonth].short }}
        </p>
        <span>{{ getDaysInMonth(prevMonth) }}</span>
      </button>
      <button
        class="header-days-button"
        :class="{
          today:
            index == today.getDate() &&
            selectedMonth == today.getMonth() &&
            selectedYear == today.getFullYear(),
          highlighted:
            index == selectedDay &&
            selectedMonth == date.getMonth() &&
            selectedYear == date.getFullYear()
        }"
        v-for="index in getDaysInMonth(selectedMonth)"
        :key="index"
        @click="changeDay(index)"
        :disabled="disabledDates(index)"
        v-show="validateDay(index)"
      >
        <p>
          {{ formattedDigit(index) }}
        </p>
        <span>
          {{ weekDays[getWeekDay(index)].short }}
        </span>
      </button>
      <button
        class="header-days-button"
        @click="getNextMonth"
        :disabled="disabled ? true : blockDates(nextDate)"
        v-show="showNextMonth"
      >
        <p>
          {{ monthNames[nextMonth].short }}
        </p>
        <span> 01 </span>
      </button>
    </div>
    <div class="header-days-navigation">
      <button @click="panRight" v-show="showPanRight">
        <CaretRight />
      </button>
    </div>
  </div>
</template>
<script>
  import CaretLeft from '@/assets/images/icons/calendar-caret-left.svg';
  import CaretRight from '@/assets/images/icons/calendar-caret-right.svg';

  export default {
    components: { CaretLeft, CaretRight },
    props: ['date', 'weekDays', 'monthNames', 'disabled', 'showPicker', 'blockDates', 'showDates'],
    data() {
      return {
        today: new Date(),
        selectedMonth: this.date.getMonth(),
        selectedYear: this.date.getFullYear(),
        selectedDay: this.date.getDate(),
        showPrevMonth: false,
        showNextMonth: false,
        showPanLeft: true,
        showPanRight: true
      };
    },
    computed: {
      prevMonth() {
        return this.selectedMonth - 1 == -1 ? 11 : this.selectedMonth - 1;
      },
      nextMonth() {
        return this.selectedMonth + 1 == 12 ? 0 : this.selectedMonth + 1;
      },
      prevDate() {
        var prevMonth = this.selectedMonth - 1 == -1 ? 11 : this.selectedMonth - 1;
        var prevYear = prevMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
        return new Date(prevYear, prevMonth, 31);
      },
      nextDate() {
        var nextMonth = this.selectedMonth + 1 == 12 ? 0 : this.selectedMonth + 1;
        var nextYear = nextMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
        return new Date(nextYear, nextMonth, 1);
      }
    },
    mounted() {
      const list = this.$refs.daysList;
      const vm = this;

      $(() => {
        const preventClick = (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        };

        let mouseDown = false;
        var isDragged = false;
        let startX;
        let scrollLeft;

        const startDragging = function (e) {
          list.classList.add('dragging');
          mouseDown = true;
          startX = e.pageX - list.offsetLeft;
          scrollLeft = list.scrollLeft;
        };

        const stopDragging = function (event) {
          list.classList.remove('dragging');
          mouseDown = false;
        };

        list.addEventListener('mouseup', (e) => {
          mouseDown = false;
          const elements = document.getElementsByClassName('book');
          if (isDragged) {
            for (let i = 0; i < elements.length; i++) {
              elements[i].addEventListener('click', preventClick);
            }
          } else {
            for (let i = 0; i < elements.length; i++) {
              elements[i].removeEventListener('click', preventClick);
            }
          }
          list.classList.remove('active');
          isDragged = false;
        });

        list.addEventListener('mousemove', (e) => {
          if (!mouseDown) return;
          isDragged = true;
          e.preventDefault();
          const x = e.pageX - list.offsetLeft;
          const walk = (x - startX) * 2;
          list.scrollLeft = scrollLeft - walk;
          vm.validatePan();
        });

        list.addEventListener('scroll', (e) => {
          vm.validatePan();
        });

        list.addEventListener('mousedown', startDragging, false);
        list.addEventListener('mouseleave', stopDragging, false);
      });

      this.scrollActual();

      this.validateNavigation();
    },
    methods: {
      validateDay(day) {
        var date = new Date(this.selectedYear, this.selectedMonth, day);
        return this.validateDate(date);
      },
      disabledDates(day) {
        return this.disabled
          ? true
          : this.blockDates(new Date(this.selectedYear, this.selectedMonth, day));
      },
      validateNavigation() {
        this.showPrevMonth = this.validateDate(this.prevDate);
        this.showNextMonth = this.validateDate(this.nextDate);
      },
      validateDate(date) {
        if (this.showDates.length) {
          if (date >= this.showDates[0] && date <= this.showDates[1]) return true;
          return false;
        } else {
          return true;
        }
      },
      scrollActual() {
        const list = this.$refs.daysList;
        this.$nextTick(() => {
          const selected = list.querySelector('.highlighted');

          if (selected) {
            list.scrollLeft = (selected.offsetWidth + 8) * this.selectedDay;
          }
          this.validatePan();
        });
      },
      getPrevMonth() {
        this.selectedMonth = this.prevMonth;
        if (this.selectedMonth == 11) {
          this.selectedYear -= 1;
        }
      },
      getNextMonth() {
        this.selectedMonth = this.nextMonth;
        if (this.selectedMonth == 0) {
          this.selectedYear += 1;
        }
        this.$nextTick(() => {
          const list = this.$refs.daysList;
          list.scrollLeft = 0;
          this.showPanRight = true;
        });
      },
      changeDay(day) {
        this.selectedDay = day;
        this.$emit('changeDate', {
          day,
          month: this.selectedMonth,
          year: this.selectedYear
        });
      },
      formattedDigit(value) {
        return String(value).padStart(2, '0');
      },
      getWeekDay(day) {
        return new Date(this.selectedYear, this.selectedMonth, day).getDay();
      },
      getDaysInMonth(month) {
        return new Date(this.selectedYear, month + 1, 0).getDate();
      },
      getMaxDayInMonth(month) {
        if (this.showDates.length) {
          var maxMonth = this.showDates[1].getMonth();
          if (month == maxMonth) {
            return this.showDates[1].getDate() - 2;
          }
        }
        return this.getDaysInMonth(month) + 2;
      },
      panLeft() {
        const list = this.$refs.daysList;
        list.scrollLeft = list.scrollLeft - list.firstChild.offsetWidth + 8;
        this.validatePan();
      },
      panRight() {
        const list = this.$refs.daysList;
        list.scrollLeft = list.scrollLeft + list.firstChild.offsetWidth + 8;
        this.validatePan();
      },
      clearPan() {
        this.showPanLeft = true;
        this.showPanRight = true;
      },
      validatePan() {
        const list = this.$refs.daysList;
        this.clearPan();
        if (list.scrollLeft == 0) {
          this.showPanLeft = false;
        }
        if (list.scrollLeft >= list.scrollWidth - list.offsetWidth) {
          this.showPanRight = false;
        }
      }
    },
    watch: {
      date(value) {
        this.selectedMonth = value.getMonth();
        this.selectedYear = value.getFullYear();
        this.selectedDay = value.getDate();
      },
      selectedMonth() {
        this.validateNavigation();
        this.$nextTick(() => {
          this.validatePan();
        });
      },
      showPicker() {
        this.scrollActual();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .header-days {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 8px;
    padding-block: 16px;
    background: #f7f3ef;
    border-bottom: 1px solid #cfc4be;

    .header-days-navigation {
      padding-inline: 16px;
      height: 70px;
      display: flex;
    }

    .header-days-main {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
      overflow-x: scroll;
      padding-inline: 1px;

      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .header-days-navigation button {
      background-color: transparent;
      border: none;
      border-radius: 6px;
      outline: none;

      &:hover {
        background: #ffede2;
      }
    }

    .header-days-button {
      min-width: 64px;
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding: 12px 20px;
      background-color: transparent;
      border: 1px solid #cfc4be;
      border-radius: 6px;
      position: relative;
      outline: none;
      color: #4c4541;

      p {
        font-weight: 600;
        font-size: 18px;
        margin: 0;
      }

      span {
        font-weight: 400;
        font-size: 12px;
      }

      &.today::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        left: calc(50% - 20px / 2);
        top: 5px;

        background: #974900;
        border-radius: 6px;
      }

      &.highlighted {
        background-color: #974900;
        color: #fff;

        &.today::before {
          background: #fff;
        }
      }

      &:disabled {
        color: #cfc4be;
      }

      &:hover:not(.highlighted) {
        color: #974900;
        background: #ffede2;
        border: 1px solid #974900;
      }
    }
  }

  @media (max-width: 1000px) {
    .header-days {
      gap: 27px;
      padding: 16px 29px;
      .header-days-main {
        gap: 16px;
      }
      .header-days-navigation {
        padding-inline: 29px;
        svg {
          transform: scale(4.5);
        }
      }

      .header-days-button {
        min-width: 120px;
        height: 130px;
        padding: 30px;

        p {
          font-size: 46px;
          line-height: 50px;
        }

        span {
          font-size: 18px;
          line-height: 26px;
        }
        &.today::before {
          width: 34px;
          height: 4px;
          left: calc(50% - 34px / 2);
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 635px) {
    .header-days {
      gap: 27px;
      padding: 10px 29px;

      .header-days-button {
        min-width: 100px;
        height: 80px;
        padding: 30px;

        p {
          font-size: 26px;
          line-height: 30px;
        }

        span {
          font-size: 15px;
          line-height: 26px;
        }
        &.today::before {
          width: 26px;
          height: 4px;
          left: calc(50% - 26px / 2);
        }
      }
    }
  }

  @media (max-width: 1000px) and (max-height: 405px) {
    .header-days {
      gap: 27px;
      padding: 5px 29px;

      .header-days-main {
        gap: 8px;
      }

      .header-days-navigation {
        padding-inline: 8px;
        height: 50px;
        svg {
          transform: scale(2);
        }
      }

      .header-days-button {
        min-width: 80px;
        height: 50px;
        padding: 20px;
        gap: 0px;

        p {
          font-size: 20px;
          line-height: 15px;
        }

        span {
          font-size: 15px;
          line-height: 26px;
        }
        &.today::before {
          display: none;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .header-days {
      gap: 16px;
      padding: 16px;
      .header-days-main {
        gap: 8px;
      }
      .header-days-navigation {
        padding-inline: 16px;
        svg {
          transform: scale(1);
        }
      }

      .header-days-button {
        min-width: 55px;
        height: 70px;
        padding: 16px;

        p {
          font-size: 14px;
          line-height: 20px;
        }

        span {
          font-size: 10px;
          line-height: 14px;
        }
        &.today::before {
          width: 20px;
          height: 2px;
          left: calc(50% - 20px / 2);
        }
      }
    }
  }
</style>
